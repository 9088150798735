"use client";

import classes from "./main-navigation.module.css";
import Link from "next/link";
import Image from "next/image";
import {
  ShoppingBag,
  House,
  UserRound,
  Heart,
  Bell,
  MessageCircle,
} from "lucide-react";
import SearchBar from "../../NavbarComponents/SearchBar";
import { useEffect, useRef, useState } from "react";
import { useCartContext } from "@/context/cart";
import Button from "@/components/UI/Buttons/button";

import { useAuthContext } from "@/context";
import { BurgerIcon } from "@/components/UI/burger-icon/burger-icon";
import ProfileModal from "./modals/profile-modal";
import CartModal from "./modals/cart-modal";
import NotificationsModal from "./modals/notifications-modal";
import { useFavoritesContext } from "@/context/favorites";
import { useNotificationsContext } from "@/context/notifications";
import { useChatsContext } from "@/context/chats";
import { useSearchParams } from "next/navigation";
import AllChatsOverlay from "@/components/Chats/AllChatsOverlay";

export default function MainNavigation() {
  const { cart } = useCartContext();
  const { auth } = useAuthContext();
  const { favorites } = useFavoritesContext();
  const { chats, REMOVE_CHAT_NOT } = useChatsContext();
  const [chatSelection, setChatSelection] = useState(null);

  const {
    notifications,
    LOAD_NOTIFICATIONS,
    PREVIEW_NOTIFICATIONS,
    REMOVE_NOTIFICATION,
  } = useNotificationsContext();

  const profileMenuRef = useRef(null);
  const cartMenuRef = useRef(null);
  const notificationMenuRef = useRef(null);
  const chatMenuRef = useRef(null);
  const chatRef = useRef(null);

  const [showMenu, setShowMenu] = useState({
    cartMenu: false,
    profileMenu: false,
    notificationMenu: false,
    chatMenu: false,
  });

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        profileMenuRef.current &&
        !profileMenuRef.current.contains(event.target)
      ) {
        setShowMenu((prev) => ({ ...prev, profileMenu: false }));
      }
      if (cartMenuRef.current && !cartMenuRef.current.contains(event.target)) {
        setShowMenu((prev) => ({ ...prev, cartMenu: false }));
      }
      if (
        notificationMenuRef.current &&
        !notificationMenuRef.current.contains(event.target)
      ) {
        setShowMenu((prev) => ({ ...prev, notificationMenu: false }));
      }
      if (
        (chatMenuRef.current && chatMenuRef.current.contains(event.target)) ||
        (chatRef.current && chatRef.current.contains(event.target))
      ) {
        return; // Don't close if the click is inside chat menu
      }
      setShowMenu((prev) => ({ ...prev, chatMenu: false }));
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const searchParams = useSearchParams(); // Use this to access query parameters

  useEffect(() => {
    const chat = searchParams.get("chat");
    const listing = searchParams.get("listing");
    if (chat || listing) {
      setShowMenu((prev) => ({ ...prev, chatMenu: true }));
    }
  }, [searchParams]);

  return (
    <>
      <section className={classes.wrapper}>
        <section className={classes.sectionWrapper}>
          <Link className={classes.logoSection} href="/">
            <div className={classes.logoWrapper}>
              <Image
                src="https://ik.imagekit.io/kuj9zqlrx0or/Static/logos/logo-mark-white.png"
                alt="KASHEW Second Hand Furniture San Francisco Logo Mark"
                className={classes.logoMark}
                width={22}
                height={22}
              />
            </div>
            <img
              src="https://ik.imagekit.io/kuj9zqlrx0or/Static/logos/logo-black.png"
              alt="KASHEW Second Hand Furniture San Francisco Logo Full"
              className={classes.logo}
            />
          </Link>

          <div className={`${classes.links} `}>
            <Link href="/explore" className={`${classes.navBubble} `}>
              Explore
            </Link>
            <Link href="/collections" className={classes.navBubble}>
              Shop
            </Link>
          </div>
        </section>

        <SearchBar className={classes.searchBar} />

        <section className={classes.links}>
          <Link
            className={`${classes.navBubble} ${classes.mobileNotVisible}`}
            href="/consign-with-us"
          >
            Start Selling
          </Link>

          {auth.isAuthenticated && (
            <>
              <section className={classes.buttonModalWrapper}>
                <div
                  className={classes.navBubble}
                  ref={notificationMenuRef}
                  onClick={() =>
                    setShowMenu((prev) => ({
                      ...prev,
                      notificationMenu: !prev.notificationMenu,
                    }))
                  }
                >
                  {notifications.notPreviewed > 0 && (
                    <div className={classes.notificationNumber}>
                      {notifications.notPreviewed}
                    </div>
                  )}
                  <Bell className={classes.icon} />
                </div>

                <NotificationsModal
                  showMenu={showMenu.notificationMenu}
                  removeNotification={REMOVE_NOTIFICATION}
                  notifications={notifications}
                  loadNotifications={LOAD_NOTIFICATIONS}
                  setShowNotifications={(value) =>
                    setShowMenu((prev) => ({
                      ...prev,
                      notificationMenu: value,
                    }))
                  }
                />
              </section>

              <section
                className={classes.buttonModalWrapper}
                ref={chatMenuRef}
                onClick={(e) => {
                  e.stopPropagation(); // Prevents click from bubbling up
                  setShowMenu((prev) => ({
                    ...prev,
                    chatMenu: !prev.chatMenu,
                  }));
                }}
              >
                <div className={classes.navBubble}>
                  {chats.notifications > 0 && (
                    <div className={classes.notificationNumber}>
                      {chats.notifications}
                    </div>
                  )}
                  <MessageCircle className={classes.icon} />
                </div>
              </section>

              <Link href="/favorites" className={classes.navBubble}>
                {favorites?.listings?.length > 0 && (
                  <div className={classes.notificationNumber}>
                    {favorites.listings.length}
                  </div>
                )}
                <Heart className={classes.icon} />
              </Link>
            </>
          )}

          <section
            className={classes.buttonModalWrapper}
            ref={cartMenuRef}
            onClick={() =>
              setShowMenu((prev) => ({ ...prev, cartMenu: !prev.cartMenu }))
            }
          >
            <div className={classes.navBubble}>
              {cart?.listings?.length > 0 && (
                <div className={classes.notificationNumber}>
                  {cart.listings.length}
                </div>
              )}

              <ShoppingBag className={classes.icon} />
            </div>
            <CartModal
              showMenu={showMenu.cartMenu}
              setShowMenu={(value) =>
                setShowMenu((prev) => ({ ...prev, cartMenu: value }))
              }
            />
          </section>
          {!auth.isAuthenticated ? (
            <Button href="/login">Log in</Button>
          ) : (
            <section
              className={classes.buttonModalWrapper}
              ref={profileMenuRef}
            >
              <section
                className={`${classes.navBubbleBorder} ${
                  showMenu.profileMenu ? classes.active : ""
                }`}
                onClick={() =>
                  setShowMenu((prev) => ({
                    ...prev,
                    profileMenu: !prev.profileMenu,
                  }))
                }
              >
                <UserRound className={`${classes.linkIcon} `} />
                <span className={classes.burgerIcon}>
                  {" "}
                  <BurgerIcon
                    isOpen={showMenu.profileMenu}
                    strokeWidth="2"
                    color="#fd589e"
                    colorClosed="#070035"
                    lineProps={{ strokeLinecap: "round" }}
                    transition={{ type: "spring", stiffness: 260, damping: 20 }}
                  />{" "}
                </span>
              </section>
              <ProfileModal
                auth={auth}
                showMenu={showMenu.profileMenu}
                setShowNavMenu={(value) =>
                  setShowMenu((prev) => ({ ...prev, profileMenu: value }))
                }
              />
            </section>
          )}
        </section>
      </section>
      <AllChatsOverlay
        chatRef={chatRef}
        open={showMenu.chatMenu}
        setOpen={(value) =>
          setShowMenu((prev) => ({ ...prev, chatMenu: value }))
        }
        chatSelection={chatSelection}
        setChatSelection={setChatSelection}
      />
    </>
  );
}
