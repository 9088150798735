"use client";
import { motion, AnimatePresence } from "framer-motion";
import classes from "./modals.module.css";
import Link from "next/link";
import { useAuthContext } from "@/context/";

const ProfileModal = ({ auth, showMenu, setShowNavMenu }) => {
  const { LOGOUT } = useAuthContext();

  const logoutHandler = async () => {
    LOGOUT();
  };

  const menuSections = [
    {
      section: "Selling",
      links: [
        {
          href: "/seller-dashboard?tab=home",
          text: "Dashboard",
          visible: auth.user.isBusiness,
        },
        {
          href: "/consign-with-us",
          text: "Start Selling",
          visible: !auth.user.isBusiness,
        },
        {
          href: auth.user.isBusiness
            ? "/seller-dashboard?tab=products"
            : "/your-listings",
          text: `My ${auth.user.isBusiness ? "Products" : "Consignments"}`,
          visible: true,
        },
        {
          href: "/sales",
          text: "My Sales",
          visible: auth.user.isBusiness,
        },
      ],
    },
    {
      section: "Buying",
      links: [
        {
          href: "/purchases",
          text: "My Purchases",
          visible: true,
        },
        {
          href: "/favorites",
          text: "Favorites",
          visible: true,
        },
      ],
    },
    {
      section: "Admin",
      links: [
        {
          href: "https://admin.kashew.com",
          text: "Admin",
          visible: auth.user.admin,
          external: true,
        },
      ],
    },
    {
      section: "Settings",
      links: [
        {
          href: auth.user.isBusiness
            ? "/seller-dashboard?tab=shop_details"
            : "/settings",
          text: `${auth.user.isBusiness ? "Shop" : ""} Settings`,
          visible: true,
        },
      ],
    },
  ];

  return (
    <AnimatePresence>
      {showMenu && (
        <motion.div
          initial={{ opacity: 0, x: "100px" }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: "100px" }} // Ensures it animates out
          transition={{
            duration: 0.4,
            type: "spring",
            stiffness: 400,
            damping: 40,
          }}
          className={classes.wrapper}
          onClick={() => setShowNavMenu(false)}
        >
          {/* Profile Section */}
          <Link
            href={`/profile/${auth.user._id}`}
            className={classes.profileNavMenuItemTop}
          >
            <img
              className={classes.profilePic}
              src={`${auth.user.picture}?tr=w-60,h-60`}
              referrerPolicy="no-referrer"
              alt="Profile Icon"
            />
            <div className={classes.col}>
              <h4 className={classes.largeText}>
                {auth.user.firstName}{" "}
                {auth.user.lastName && auth.user.lastName !== "undefined"
                  ? auth.user.lastName
                  : ""}
              </h4>
              <div className={classes.smallText}>
                See your {auth.user.isBusiness ? "shop" : "profile"}
              </div>
            </div>
          </Link>

          {/* Dynamic Sections */}
          {menuSections.map((section, index) => {
            const visibleLinks = section.links.filter((link) => link.visible);
            if (visibleLinks.length === 0) return null; // Skip sections with no visible links

            return (
              <div key={index}>
                <h6 className={classes.dropDownContent}>{section.section}</h6>
                {visibleLinks.map((link, idx) =>
                  link.external ? (
                    <a
                      key={idx}
                      href={link.href}
                      target="_blank"
                      rel="noreferrer"
                      className={classes.navMenuItem}
                    >
                      <div className={classes.sectionText}>{link.text}</div>
                    </a>
                  ) : (
                    <Link
                      key={idx}
                      href={link.href}
                      className={classes.navMenuItem}
                    >
                      <div className={classes.sectionText}>{link.text}</div>
                    </Link>
                  )
                )}
              </div>
            );
          })}

          {/* Logout */}
          <li onClick={logoutHandler} className={classes.navMenuItem}>
            <div className={classes.sectionText}>Logout</div>
          </li>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default ProfileModal;
