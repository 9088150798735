"use client";

import { AnimatePresence } from "framer-motion";
import AlertMsg from "./AlertMsg";
import { useAlertContext } from "@/context/alert";

const Alert = () => {
  const { alert } = useAlertContext();

  return (
    <AnimatePresence mode="wait">
      {alert?.alerts?.length > 0 &&
        alert.alerts.map((alert) => <AlertMsg alert={alert} key={alert.id} />)}
    </AnimatePresence>
  );
};

export default Alert;
