import { motion, AnimatePresence } from "framer-motion";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/navigation";
import classes from "./modals.module.css";
import { useCartContext } from "@/context/cart";
import { XCircle } from "react-feather";
import Button from "@/components/UI/Buttons/button";

const CartModal = (props) => {
  const { setShowMenu, showMenu } = props;

  const { REMOVE_FROM_CART, cart } = useCartContext();

  const router = useRouter();
  const removeFromCartHandler = (e, listing) => {
    e.preventDefault();
    REMOVE_FROM_CART(listing._id);
    process.env.NEXT_PUBLIC_NODE_ENV === "production" &&
      process.env.OG_DB === "true" &&
      process.env.OG_DB === "true" &&
      global?.window &&
      window.analytics.track("remove_from_cart", {
        currency: "USD",
        value: listing.price * 0.2,
        items: [
          {
            item_id: listing._id,
            item_name: listing.title,
            currency: "USD",
            item_brand: listing.brand
              ? listing.brand
              : listing.velouAttributes?.manufacturer?.length > 0
              ? listing.velouAttributes?.manufacturer[0]
                  ?.replace(/_/g, " ")
                  ?.split(" ")
                  ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  ?.join(" ")
              : listing.velouAttributes?.brands?.length > 0
              ? listing.velouAttributes?.brands[0]
                  ?.replace(/_/g, " ")
                  ?.split(" ")
                  ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  ?.join(" ")
              : "",
            item_category: listing.newSchema.categories[0],
            item_category2: listing.newSchema.subCategories[0]
              ? listing.newSchema.subCategories[0]
              : "",
            item_category3: listing.newSchema.types[0]
              ? listing.newSchema.types[0]
              : "",
            price: listing.price,
          },
        ],
      });
  };

  const goToCheckoutHandler = () => {
    let items = [];
    for (let listing of cart.listings) {
      items.push({ id: listing._id, listingPrice: listing.price });
    }

    global?.window && localStorage.setItem("checkout", JSON.stringify(items));
    global?.window && localStorage.removeItem("offerInfo");
    router.push("/checkout");
  };

  return (
    <>
      <AnimatePresence>
        {showMenu && (
          <motion.div
            initial={{ opacity: 0, x: "100px" }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: "100px" }} // Ensures it animates out
            transition={{
              duration: 0.4,
              type: "spring",
              stiffness: 400,
              damping: 40,
            }}
            className={classes.wrapper}
            onClick={() => setShowMenu(false)}
          >
            <div className={classes.header}>
              Your Cart{" "}
              {cart.listings.length > 0
                ? ` - (${cart.listings.length} items)`
                : ""}
            </div>

            {cart.loading && (
              <>
                <div className={`${classes.cartMenuItem} ${classes.border}`}>
                  <div className={classes.placeholderBox} />
                  <div className={classes.col}>
                    <div className={classes.textPlaceholderLong} />
                    <div className={classes.textPlaceholderShort} />
                  </div>
                </div>
                <div className={classes.cartMenuItem}>
                  <div className={classes.placeholderBox} />
                  <div className={classes.col}>
                    <div className={classes.textPlaceholderLong} />
                    <div className={classes.textPlaceholderShort} />
                  </div>
                </div>
              </>
            )}
            <div className={classes.overflow}>
              {cart.listings.length > 0 && !cart.loading ? (
                <>
                  {cart.listings.map(
                    (listing, idx) =>
                      listing && (
                        <Link
                          className={`${classes.cartMenuItem} ${
                            idx !== cart.listings.length - 1
                              ? classes.border
                              : ""
                          }`}
                          key={idx}
                          href={`/product/${listing.kashewId}/${listing?.title
                            ?.toLowerCase()
                            .replace(/[_\W]+/g, "-")
                            .replace(/^-+/, "")
                            .replace(/-+$/, "")}`}
                        >
                          <Image
                            width={50}
                            height={50}
                            src={listing.images[0]}
                            className={classes.listingImage}
                            alt={listing.title}
                          />
                          <div className={classes.col}>
                            <h4 className={classes.largeText}>
                              {listing.title}
                            </h4>
                            <p className={classes.smallText}>
                              ${listing.price}
                            </p>
                          </div>
                          <XCircle
                            size={8}
                            className={classes.delete}
                            onClick={(e) => removeFromCartHandler(e, listing)}
                          />
                        </Link>
                      )
                  )}
                </>
              ) : !cart.loading ? (
                <div className={classes.buySomeShit}>
                  <Image
                    width={70}
                    height={70}
                    src={
                      "https://ik.imagekit.io/kuj9zqlrx0or/Static/emptyBox.png"
                    }
                    className={classes.emptyBox}
                    alt="No consignment offers used furnitue Kashew"
                  />
                  <div className={classes.col}>
                    <h3 className={classes.buyText}>
                      Here I am, an empty basket, patiently waiting for an item
                      to call my own.
                    </h3>
                    <div
                      className={classes.buyLink}
                      onClick={() => router.push("/collections")}
                    >
                      Explore Kashew
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            {cart.listings.length > 0 && (
              <div className={classes.buttonWrapper}>
                <Button
                  onClick={goToCheckoutHandler}
                  className={classes.fullWidth}
                >
                  Go to Checkout
                </Button>
              </div>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};
export default CartModal;
