'use client';

import SellerReviewsCard from '@/components/UI/GoogleReview/SellersReviewCard';
import classes from './footer-reviews.module.css';
import axios from 'axios';

import { useEffect, useState } from 'react';
import { usePathname } from 'next/navigation';

export default function FooterReviews() {
	const pathname = usePathname();
	const [sellerReviews, setSellerReviews] = useState([]);
	const [getLoading, setGetLoading] = useState(false);

	const getSellerReviews = async () => {
		console.log('GETTING SELLER REVIEWS');
		await axios
			.get(
				`${process.env.NEXT_PUBLIC_SERVER_API}/api/other/review/seller-all-reviews?limit=6`
				// limit the number of reviews to 6
			)
			.then((res) => {
				setSellerReviews(res.data);
			})
			.catch((err) => {
				SET_ALERT({ msg: 'Error fetching review', type: 'danger' });
			});
		setGetLoading(false);
	};

	useEffect(() => {
		setGetLoading(true);
		getSellerReviews();
	}, []);

	return (
		<>
			{!pathname.includes('product') && (
				<section className={classes.wrapper}>
					{getLoading
						? ''
						: sellerReviews.reviews &&
						  sellerReviews.reviews
								.sort((a, b) => new Date(b.order.date) - new Date(a.order.date))
								.slice(0, 8)
								.map((review, i) => (
									<SellerReviewsCard review={review} key={i} />
								))}
				</section>
			)}
		</>
	);
}
