"use client";
import { motion } from "framer-motion";
import { useEffect } from "react";
import classes from "./AlertMsg.module.css";
import { useAlertContext } from "@/context/alert";
import { CircleSlash, CircleCheck } from "lucide-react";

const AlertMsg = ({ alert }) => {
  const { REMOVE_ALERT } = useAlertContext();

  useEffect(() => {
    const timer = setTimeout(() => {
      REMOVE_ALERT(alert.id);
    }, 7000); // Keep 7s duration

    return () => clearTimeout(timer); // Cleanup in case component unmounts
  }, [alert.id]);
  const closeAlert = () => REMOVE_ALERT(alert.id);

  return (
    <>
      {alert && (
        <motion.div
          initial={{ y: "20vh", opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: "20vh", opacity: 0 }}
          transition={{
            duration: 0.5,
            type: "spring",
          }}
          className={classes.wrapper}
        >
          <div className={`${classes.alert} ${classes[alert.type]}`}>
            <section className={classes.content}>
              {alert.type == "danger" ? (
                <CircleSlash
                  className={classes.iconMessage}
                  onClick={closeAlert}
                  size={15}
                />
              ) : (
                <CircleCheck
                  className={classes.iconMessage}
                  onClick={closeAlert}
                  size={15}
                />
              )}
              <div className={classes.msg}>{alert.msg}</div>
            </section>

            {alert && (
              <div className={`${classes.progressBar} ${classes[alert.type]}`}>
                <div className={classes.progress} />
              </div>
            )}
            {/* <button
              className={`${classes.closeIconWrapper} ${classes[alert.type]}`}
              onClick={closeAlert}
            >
              <X className={classes.closeIcon} />
            </button> */}
          </div>
        </motion.div>
      )}
    </>
  );
};

export default AlertMsg;
