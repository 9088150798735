"use client";

import axios from "axios";
import { AnimatePresence, motion } from "framer-motion";
import moment from "moment";
import { Spinner } from "react-bootstrap";
import Link from "next/link";
import classes from "./modals.module.css";
import { useNotificationsContext } from "@/context/notifications";
import { useAuthContext } from "@/context";
import { useAlertContext } from "@/context/alert";
import { Dot } from "lucide-react";
import { DollarSign, Gift, MessageCircle, Tag } from "react-feather";

// Map icons to notification types with proper wrappers
const notificationIcons = {
  offer: (
    <div className={`${classes.iconWrapper} ${classes.green}`}>
      <Tag className={classes.iconNot} />
    </div>
  ),
  "offer-declined": (
    <div className={`${classes.iconWrapper} ${classes.red}`}>
      <Tag className={classes.iconNot} />
    </div>
  ),
  "counter-offer": (
    <div className={`${classes.iconWrapper} ${classes.green}`}>
      <DollarSign className={classes.iconNot} />
    </div>
  ),
  "offer-accepted": (
    <div className={`${classes.iconWrapper} ${classes.yellow}`}>
      <Tag className={classes.iconNot} />
    </div>
  ),
  "new-chat-message": (
    <div className={`${classes.iconWrapper} ${classes.blue}`}>
      <MessageCircle className={classes.iconNot} />
    </div>
  ),
  "new-order": (
    <div className={`${classes.iconWrapper} ${classes.green}`}>
      <Tag className={classes.iconNot} />
    </div>
  ),
  "payment-received": (
    <div className={`${classes.iconWrapper} ${classes.yellow}`}>
      <Tag className={classes.iconNot} />
    </div>
  ),
  "new-consignment-request": (
    <div className={`${classes.iconWrapper} ${classes.beige}`}>
      <Gift className={classes.iconNot} />
    </div>
  ),
  "listing-request": (
    <div className={`${classes.iconWrapper} ${classes.blue}`}>
      <MessageCircle className={classes.iconNot} />
    </div>
  ),
  "listing-updated": (
    <div className={`${classes.iconWrapper} ${classes.green}`}>
      <Tag className={classes.iconNot} />
    </div>
  ),
  "request-reply": (
    <div className={`${classes.iconWrapper} ${classes.blue}`}>
      <MessageCircle className={classes.iconNot} />
    </div>
  ),
  "price-reduced": (
    <div className={`${classes.iconWrapper} ${classes.red}`}>
      <DollarSign className={classes.iconNot} />
    </div>
  ),
  // You can add additional icon mappings here for other types if needed
};

const defaultIcon = (
  <div className={`${classes.iconWrapper} ${classes.default}`}>
    <Tag className={classes.iconNot} />
  </div>
);

// Helper to format the title as in the original component
const formatTitle = (title) =>
  title
    ? title
        .toLowerCase()
        .replace(/[_\W]+/g, "-")
        .replace(/^-+/, "")
        .replace(/-+$/, "")
    : "";

const getNotificationLink = (notification) => {
  const formattedTitle = formatTitle(notification.listing?.title);
  switch (notification.type) {
    case "offer":
    case "offer-declined":
    case "counter-offer":
      return `/product/${notification.listing?.kashewId}/${formattedTitle}`;
    case "offer-accepted":
      return `/purchase?&tab=purchase&selection=openPurchase&order=${notification.order}`;
    case "new-offer":
      return `/sales?&tab=offer&selection=openOffers&order=${notification.order}`;
    case "listing-request":
      return `/product/${notification.listing?.kashewId}/${formattedTitle}#askSellerTarget`;
    case "listing-updated":
      return `/product/${notification.listing?.kashewId}/${formattedTitle}`;
    case "delivery-upgrade-accepted":
      return `/purchases?&tab=purchases&selection=openPurchases&order=${notification.order}`;
    case "request-reply":
      return `/product/${notification.listing?.kashewId}/${formattedTitle}#askSellerTarget`;
    case "new-chat-message":
      return `?chat=${notification.chat}`;
    case "new-order":
      return `/sales?&tab=sales&selection=openSales&order=${notification.order}`;
    case "cancellation-request":
      return `/sales?&tab=sales&selection=openSales&order=${notification.order}`;
    case "order-accepted":
      return `/purchase?&tab=purchase&selection=openPurchase&order=${notification.order}`;
    case "counteroffer-accepted":
      return `/sales?&tab=sales&selection=openSales&order=${notification.order}`;
    case "order-deleted":
      return `/purchases?&tab=purchases&selection=closedPurchases&order=${notification.order}`;
    case "new-timeslots":
      return `/orders?chat=${notification.chat}`;
    case "payment-received":
      return `/seller-dashboard?tab=payouts`;
    case "order-reschedule":
      return `/orders?chat=${notification.chat}`;
    case "completed-purchase":
    case "completed-sale":
      return "#"; // The click will trigger review handling
    case "counteroffer-updated":
      return `/sales?&tab=sales&selection=openSales&order=${notification.order}`;
    case "price-reduced":
      return `/product/${notification.listing?.kashewId}/${formattedTitle}`;
    case "new-consignment-request":
      return `/seller-dashboard?tab=consignments`;
    default:
      return "#";
  }
};

const NotificationsModal = ({
  setShowNotifications,
  showMenu,
  counterOffer,
  removeNotification,
  lastNot,
}) => {
  const { SET_ALERT } = useAlertContext();
  const { notifications } = useNotificationsContext();
  const { LOAD_USER } = useAuthContext();

  moment.locale("en", {
    relativeTime: {
      future: "in %s",
      past: "%s",
      s: "seconds ago",
      ss: "%ss",
      m: "minutes ago",
      mm: "%dm",
      h: "an hour",
      hh: "%dh",
      d: "a day",
      dd: "%dd",
      M: "%dM",
      MM: "%dM",
      y: "a year",
      yy: "%dY",
    },
  });

  const handleReviewNotification = async (notification, e) => {
    removeNotification(notification._id, e);

    try {
      await axios.post(
        `${process.env.NEXT_PUBLIC_SERVER_API}/api/auth/review-not`,
        { notification }
      );
      LOAD_USER();
    } catch {
      SET_ALERT({
        msg: "You have already reviewed this order",
        type: "success",
      });
    }
  };

  const formatDate = (date) => moment(date).fromNow();

  const getListingImage = (notification) =>
    notification.listing?.images?.[0] ||
    notification.listings?.[0]?.listing?.images?.[0] ||
    notification.items?.[0]?.images?.[0] ||
    "/default-placeholder.png"; // Fallback image

  return (
    <AnimatePresence>
      {showMenu && (
        <motion.div
          initial={{ opacity: 0, x: "100px" }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: "100px" }}
          transition={{
            duration: 0.4,
            type: "spring",
            stiffness: 400,
            damping: 40,
          }}
          className={`${classes.wrapper} ${classes.large}`}
          // onClick={() => setShowNotifications(false)}
        >
          <div className={classes.header}>Notifications</div>
          <div className={classes.overflow}>
            {notifications.notifications.length > 0 ? (
              notifications.notifications
                .sort((a, b) => (a.date < b.date ? 1 : -1))
                .map((notification) => (
                  <NotificationItem
                    key={notification._id}
                    notification={notification}
                    removeNotification={removeNotification}
                    counterOffer={counterOffer}
                    handleReviewNotification={handleReviewNotification}
                    formatDate={formatDate}
                    getListingImage={getListingImage}
                    getNotificationLink={getNotificationLink}
                    notificationIcons={notificationIcons}
                  />
                ))
            ) : notifications.loading ? (
              <Spinner animation="border" variant="success" />
            ) : (
              <div className={classes.notificationNavMenuItem}>
                <h4 className={classes.notificationText}>No notifications</h4>
              </div>
            )}
            <div ref={lastNot} />
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

const NotificationItem = ({
  notification,
  removeNotification,
  counterOffer,
  handleReviewNotification,
  formatDate,
  getListingImage,
  getNotificationLink,
  notificationIcons,
}) => {
  const handleClick = (e) => {
    if (["completed-purchase", "completed-sale"].includes(notification.type)) {
      handleReviewNotification(notification, e);
    } else if (["offer", "counter-offer"].includes(notification.type)) {
      counterOffer(notification);
    } else {
      removeNotification(notification._id, e);
    }
  };

  return (
    <Link
      onClick={handleClick}
      href={getNotificationLink(notification)}
      className={`${classes.cartMenuItem} ${classes.border}`}
    >
      <div className={classes.imageWrapper}>
        <img
          src={getListingImage(notification)}
          className={classes.listingImage}
          alt={notification.listing?.title || "Notification"}
        />
        {notification.listings && notification.listings.length > 1 && (
          <div className={classes.plusItems}>
            +{notification.listings.length - 1}
          </div>
        )}
      </div>
      {notificationIcons[notification.type] || defaultIcon}
      <div className={classes.message}>
        <h4
          className={
            notification.seen
              ? classes.notificationText
              : classes.notificationTextNotSeen
          }
        >
          <b className={classes.boldText}>{notification.sender?.firstName}</b>{" "}
          {notification.text || "sent you a notification"}
        </h4>
        <h6 className={classes.sent}>
          <Dot className={classes.dot} /> {formatDate(notification.date)}
        </h6>
      </div>
      {!notification.seen && <div className={classes.chatNotification} />}
    </Link>
  );
};

export default NotificationsModal;
