import Link from "next/link";
import classes from "./footer-value-props.module.css";
import Image from "next/image";

export default function FooterValueProps() {
  const valueProps = [
    {
      title: "Find something special",
      text: "Discover unique items that you can't find anywhere else.",
      image:
        "https://ik.imagekit.io/kuj9zqlrx0or/Static/footer_icons/character_icon.png?tr=w-200,h-200",
      imgAlt: "Character icon preloved furniture Kashew",
    },

    {
      title: "Sustainable shopping",
      text: "Reduce your carbon footprint by buying second-hand.",
      image:
        "https://ik.imagekit.io/kuj9zqlrx0or/Static/footer_icons/planet_icon.png?tr=w-200,h-200",
      imgAlt: "world icon preloved furniture Kashew",
    },

    {
      title: "Buyer protection",
      text: "Get your item as described or your money back. Returns are free and we handle it for you.",
      image:
        "https://ik.imagekit.io/kuj9zqlrx0or/Static/footer_icons/return_icon.png?tr=w-200,h-200",
      imgAlt: "Return icon preloved furniture Kashew",
    },

    {
      title: "Cherry-Picked Sellers",
      text: "We rigorously vet our sellers for quality and accuracy, guaranteeing that you always receive the best the world of pre-loved furniture has to offer.",
      image:
        "https://ik.imagekit.io/kuj9zqlrx0or/Static/footer_icons/cherry_icon.png?tr=w-200,h-200",
      imgAlt: "Cherry icon preloved furniture Kashew",
    },
  ];

  return (
    <section className={classes.wrapper}>
      <section className={classes.header}>
        <h2 className={classes.title}>Why choose Kashew?</h2>

        <Link href="/how-it-works" className={classes.link}>
          How it works
        </Link>
      </section>

      <section className={classes.valuePropsGrid}>
        {valueProps.map((prop, idx) => (
          <section key={idx} className={classes.valueProp}>
            <section className={classes.iconWrapper}>
              <Image
                width={50}
                height={50}
                src={prop.image}
                alt={prop.imgAlt}
                className={classes.icon}
              />

              <h3 className={classes.h3}>{prop.title}</h3>
            </section>

            <p className={classes.p}>{prop.text}</p>
          </section>
        ))}
      </section>
    </section>
  );
}
