"use client";

import { Suspense, useRef, useState } from "react";
import SearchBar from "@/components/Layout/NavbarComponents/SearchBar";
import classes from "./secondary-navbar-mobile.module.css";
import MainNavigationMobile from "@/components/Layout/NavbarComponents/MainNavigationMobile";
import { useAuthContext } from "@/context";

const SecondaryNavbarMobile = () => {
  const [expanded, setExpanded] = useState(false);
  const refToggle = useRef();
  const { auth } = useAuthContext();

  return (
    <>
      <div className={classes.wrapper}>
        <Suspense fallback={<div></div>}>
          <MainNavigationMobile
            expanded={expanded}
            refToggle={refToggle}
            setExpanded={setExpanded}
            auth={auth.isAuthenticated}
          />
        </Suspense>
        <div
          onClick={() => setExpanded(true)}
          ref={refToggle}
          className={classes.navToggle}
        >
          <svg viewBox="0 0 100 80" width="15" height="15">
            <rect width="100" height="15" rx="8"></rect>
            <rect y="30" width="100" height="15" rx="8"></rect>
            <rect y="60" width="100" height="15" rx="8"></rect>
          </svg>
        </div>
        <SearchBar className={classes.searchBarClass} />
      </div>
    </>
  );
};
export default SecondaryNavbarMobile;
