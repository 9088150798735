"use client";
import Link from "next/link";
import classes from "./explore-navigation.module.css";
import { usePathname, useSearchParams } from "next/navigation";
import { useEffect, useState } from "react";

const popularBadges = [
  {
    name: "New",
    link: "new",
  },
  {
    name: "Mid Century Modern",
    link: "mcm",
  },
  {
    name: "Iconic Designers",
    link: "iconic-designers",
  },
  {
    name: "Vintage",
    link: "vintage",
  },
];

export default function ExploreNavigation() {
  const pathname = usePathname();
  const [activeBadge, setActiveBadge] = useState();

  const searchParams = useSearchParams();
  if (!pathname.includes("/explore")) {
    return null;
  }

  useEffect(() => {
    const params = new URLSearchParams(searchParams);
    const badge = params.get("tag");
    setActiveBadge(badge);
  }, [searchParams]);

  return (
    <div className={classes.wrapper}>
      <section className={classes.container}>
        <section className={classes.row}>
          {popularBadges.map((badge) => (
            <Link
              key={badge.name}
              href={
                activeBadge === badge.link
                  ? `/explore`
                  : `/explore?tag=${badge.link}`
              }
              className={`${classes.badge} ${
                activeBadge === badge.link ? classes.active : ""
              }`}
            >
              <span className={classes.text}> {badge.name}</span>
            </Link>
          ))}
        </section>
      </section>
    </div>
  );
}
