import Image from "next/image";
import classes from "./footer-links.module.css";
import SearchBar from "../../NavbarComponents/SearchBar";
import Button from "@/components/UI/Buttons/button";
import Link from "next/link";
import { Facebook, Instagram, Linkedin } from "react-feather";

export default function FooterLinks() {
  const footerLinks = [
    {
      title: "About Kashew",
      links: [
        { title: "Our story", href: "/about-us" },
        { title: "How it works", href: "/how-it-works" },
        { title: "Our blog", href: "/blog" },
        { title: "Contact us", href: "/contact" },
      ],
    },
    {
      title: "Buying",
      links: [
        { title: "Buyer protection", href: "/help/buyer-protection" },
        { title: "Buying FAQ", href: "/help?audience=Buying" },
        { title: "Explore", href: "/explore" },
        { title: "Shop", href: "/collections" },
        { title: "Trade program", href: "/trade" },
      ],
    },
    {
      title: "Selling",
      links: [
        { title: "Start selling", href: "/consign-with-us" },
        { title: "For professional Resellers", href: "/furniture-sellers" },
        { title: "Selling FAQ", href: "/help?audience=Selling" },
      ],
    },
    {
      title: "My Kashew",
      links: [
        { title: "Help center", href: "/help" },
        { title: "Login", href: "/login" },
        { title: "Register", href: "/register" },
      ],
    },
  ];
  return (
    <>
      <section className={classes.topSection}>
        <div className={classes.col}>
          <section className={classes.textSection}>
            <h3 className={classes.h3}>Live sustainably, buy second-hand.</h3>
            <p className={classes.p}>
              Discover over 10,000 items, send offers – delivered as soon as
              next day.
            </p>
          </section>
          <SearchBar className={classes.searchBar} />
        </div>
        <Image
          width={300}
          height={300}
          src="https://ik.imagekit.io/kuj9zqlrx0or/hero_landing/hero_1.2.png"
          alt="mid century modern iconic pieces on kashew"
          className={classes.footerImage}
        />
      </section>
      <section className={classes.ctaSection}>
        <section className={classes.buttons}>
          <Button color="pink" href="/login">
            Log in
          </Button>
          <Button color="pink" href="/register">
            Sign up
          </Button>
        </section>
        <p className={classes.pDescription}>
          Explore, send offers and purchase from 100’s of new items every day.
        </p>
      </section>
      <section className={classes.bottomSection}>
        <section className={classes.leftSection}>
          <section className={classes.logoSection}>
            <div className={classes.logoWrapper}>
              <Image
                src="https://ik.imagekit.io/kuj9zqlrx0or/Static/logos/logo-mark-white.png"
                alt="logo"
                className={classes.logoMark}
                width={20}
                height={20}
              />
            </div>
            <img
              src="https://ik.imagekit.io/kuj9zqlrx0or/Static/logos/logo-black.png"
              alt="logo"
              className={classes.logo}
            />
          </section>
          <p className={classes.pLogo}>
            Your online marketplace <br />
            for pre-loved gems.
          </p>
        </section>
        <section className={classes.rightSection}>
          {footerLinks.map((section, idx) => (
            <section key={idx} className={classes.section}>
              <h4 className={classes.h4}>{section.title}</h4>
              <ul className={classes.ul}>
                {section.links.map((link, idx) => (
                  <li key={idx} className={classes.li}>
                    <Link href={link.href} className={classes.link}>
                      {link.title}
                    </Link>
                  </li>
                ))}
                {section.title === "My Kashew" && ( // Add social media links
                  <li className={classes.liSocials}>
                    <Link
                      className={classes.socialWrapper}
                      href={"https://www.instagram.com/wekashew/"}
                      target="_blank"
                    >
                      <Instagram className={classes.social} />
                    </Link>
                    <Link
                      className={classes.socialWrapper}
                      href={
                        "https://www.facebook.com/pages/category/Furniture/Kashew-103649468435824/"
                      }
                      target="_blank"
                    >
                      <Facebook className={classes.social} />
                    </Link>
                    <Link
                      className={classes.socialWrapper}
                      href={"https://www.linkedin.com/company/kashew/"}
                      target="_blank"
                    >
                      <Linkedin className={classes.social} />
                    </Link>
                  </li>
                )}
              </ul>
            </section>
          ))}
        </section>
      </section>
      <section className={classes.bottomFooter}>
        <Link
          href="/help/privacy-policy"
          className={`${classes.link} ${classes.bold}`}
        >
          Privacy
        </Link>
        <Link
          href="/help/terms-of-use"
          className={`${classes.link} ${classes.bold}`}
        >
          Terms
        </Link>
        <span className={classes.span}>
          <span className={classes.coloredSpan}>©</span> 2025 Kashew
        </span>
      </section>
    </>
  );
}
