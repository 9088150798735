"use client";

import classes from "./button.module.css";
import { useRouter } from "next/navigation";

export default function Button({
  children,
  onClick,
  color,
  className,
  disabled,
  href,
}) {
  const router = useRouter();

  const handleClick = (e) => {
    if (href && href !== "") {
      router.push(href);
    }
    if (onClick) {
      e.preventDefault();
      onClick(e);
    }
  };

  return (
    <button
      disabled={disabled}
      className={`${classes.buttonStyles} ${className} ${classes[color]} ${
        disabled ? classes.disabled : ""
      }`}
      onClick={(e) => handleClick(e)}
    >
      {children}
    </button>
  );
}
