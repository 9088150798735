'use client';

import { createContext, useState, useContext, useEffect } from 'react';
import algoliasearch from 'algoliasearch';

const client =
	process.env.NEXT_PUBLIC_OG_DB === 'true'
		? algoliasearch(
				process.env.NEXT_PUBLIC_ALGOLIA_APPID,
				process.env.NEXT_PUBLIC_ALGOLIA_API_KEY
		  )
		: algoliasearch(
				process.env.NEXT_PUBLIC_DUMMY_ALGOLIA_APPID,
				process.env.NEXT_PUBLIC_DUMMY_ALGOLIA_API_KEY
		  );

// Create the cart context with default values
const ListingsContext = createContext({
	loading: false,
	listings: [],
	paginationData: {},
	uniqueBrands: [],
	uniqueRegions: [],
	uniqueMaterials: {},
	uniqueStyles: {},
	uniqueRooms: {},
	uniqueCategories: {},
	uniqueSubCategories: {},
	uniqueTypes: {},
	all: {
		loading: false,
		uniqueBrands: [],
		uniqueRegions: [],
		uniqueMaterials: {},
		uniqueStyles: {},
		uniqueRooms: {},
		uniqueCategories: {},
		uniqueSubCategories: {},
		uniqueTypes: {},
	},
	LOAD_LISTINGS: () => {},
	LOAD_ALL_NUMBERS: () => {},
});

// Create a provider component for the cart context
export const ListingsProvider = ({ children }) => {
	const [listings, setListings] = useState({
		loading: false,
		listings: [],
		paginationData: {},
		uniqueBrands: [],
		uniqueRegions: [],
		uniqueMaterials: {},
		uniqueStyles: {},
		uniqueRooms: {},
		uniqueCategories: {},
		uniqueSubCategories: {},
		uniqueTypes: {},
		all: {
			loading: false,
			uniqueBrands: [],
			uniqueRegions: [],
			uniqueMaterials: {},
			uniqueStyles: {},
			uniqueRooms: {},
			uniqueCategories: {},
			uniqueSubCategories: {},
			uniqueTypes: {},
		},
	});

	const LOAD_LISTINGS = async ({
		page = 1,
		locationSearch = '',
		userToken,
	}) => {
		setListings((prevState) => ({
			...prevState,
			loading: true,
		}));

		const searchParams = new URLSearchParams(locationSearch);
		searchParams.set('page', page);
		// Requires faceting
		let brand = searchParams.get('brand');
		let location = searchParams.get('location');
		let style = searchParams.get('style');
		let material = searchParams.get('material');

		let category = searchParams.get('category');
		let subCategory = searchParams.get('option');
		let type = searchParams.get('subType');
		let color = searchParams.get('color');
		let maxPrice = searchParams.get('maxPrice');
		let minPrice = searchParams.get('minPrice');
		let condition = searchParams.get('condition');
		let minHeight = searchParams.get('minHeight');
		let maxHeight = searchParams.get('maxHeight');
		let minLength = searchParams.get('minLength');
		let maxLength = searchParams.get('maxLength');
		let minDepth = searchParams.get('minDepth');
		let maxDepth = searchParams.get('maxDepth');
		let sort = searchParams.get('sort');
		let tags = searchParams.get('tags');
		let op = searchParams.get('op');
		let room = searchParams.get('room');
		let design = searchParams.get('design');
		let designer = searchParams.get('designer');
		let product_features = searchParams.get('product_features');
		let keyword = searchParams.get('keyword');
		let discount = searchParams.get('discount');
		let resultsPerPage = 80;

		let lat = parseFloat(localStorage.getItem('lat'))
			? parseFloat(localStorage.getItem('lat'))
			: 0;
		let lng = parseFloat(localStorage.getItem('long'))
			? parseFloat(localStorage.getItem('long'))
			: 0;

		let region =
			localStorage.getItem('region') &&
			localStorage.getItem('region') !== 'null' &&
			localStorage.getItem('region') !== 'NA'
				? localStorage.getItem('region')
				: '';

		const index = client.initIndex(
			process.env.NEXT_PUBLIC_OG_DB === 'false'
				? 'kashew_listings'
				: !sort
				? 'kashew_listings'
				: sort == 1
				? `kashew_listings_price_asc`
				: sort == 2
				? `kashew_listings_price_desc`
				: sort == 3
				? `kashew_listings_date_desc`
				: sort == 4
				? `kashew_listings_date_asc`
				: sort == 5
				? `kashew_listings_branded_deals`
				: sort == 6
				? `kashew_listings_best_deals`
				: sort == 7
				? `kashew_listings_closest`
				: sort == 0
				? `kashew_listings`
				: `kashew_listings`
		);

		let beforeSearch = global?.window && window.location.search;

		let searchObj = {
			hitsPerPage: resultsPerPage,
			clickAnalytics: true,
			page: page - 1,
			filters: `status:active${
				tags
					? ` AND tags:"${tags.replace(/-/g, ' ').replace(/\band\b/g, '&')}"`
					: ''
			}${
				color
					? ` AND (velouAttributes.product_color:"${color
							.toLowerCase()
							.replace(/-/g, ' ')
							.replace(
								/\band\b/g,
								'&'
							)}" OR velouAttributes.product_color:"${color
							.toLowerCase()
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')
							.replace(' ', '_')}")`
					: ''
			}${
				category
					? ` AND newSchema.categories:"${category
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')}"`
					: ''
			}${
				subCategory
					? ` AND newSchema.subCategories:"${subCategory
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')}"`
					: ''
			}${
				type
					? ` AND newSchema.types:"${type
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')}"`
					: ''
			}${
				brand
					? ` AND (brand:"${brand
							.replace(/-/g, ' ')
							.replace(
								/\band\b/g,
								'&'
							)}" OR velouAttributes.manufacturer:"${brand
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')}" OR velouAttributes.brands:"${brand
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')
							.replace(' ', '_')}" OR velouAttributes.manufacturer:"${brand
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')
							.replace(' ', '_')}" OR velouAttributes.brands:"${brand
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')
							.replace(' ', '_')}")`
					: ''
			}${location ? ` AND region:"${location}"` : ''}${
				style
					? ` AND (velouAttributes.styles:"${style
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')
							.replace(' ', '_')}" OR velouAttributes.styles:"${style
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')}")`
					: ''
			}${
				design
					? ` AND (velouAttributes.design:"${design
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')
							.replace(' ', '_')}" OR velouAttributes.design:"${design
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')}")`
					: ''
			}${
				designer
					? ` AND (designer:"${designer
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')
							.replace(' ', '_')}" OR velouAttributes.brands:"${designer
							.replace(/-/g, ' ')
							.replace(
								/\band\b/g,
								'&'
							)}" OR velouAttributes.designer:"${designer
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')
							.replace(' ', '_')}")`
					: ''
			}${
				material
					? ` AND (velouAttributes.materials:"${material
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')
							.replace(' ', '_')}" OR velouAttributes.materials:"${material
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')}")`
					: ''
			}${
				product_features
					? ` AND (velouAttributes.features:"${product_features
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')
							.replace(
								' ',
								'_'
							)}" OR velouAttributes.features:"${product_features
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')}")`
					: ''
			}${
				room
					? ` AND (velouAttributes.rooms:"${room
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')
							.replace(' ', '_')}" OR velouAttributes.rooms:"${room
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')}")`
					: ''
			}${minPrice ? ` AND price > ${minPrice}` : ''}${
				maxPrice ? ` AND price < ${maxPrice}` : ''
			}${minHeight ? ` AND measurements.H > ${minHeight}` : ''}${
				maxHeight ? ` AND measurements.H < ${maxHeight}` : ''
			}${minLength ? ` AND measurements.L > ${minLength}` : ''}${
				maxLength ? ` AND measurements.L < ${maxLength}` : ''
			}${minDepth ? ` AND measurements.D > ${minDepth}` : ''}${
				maxDepth ? ` AND measurements.D < ${maxDepth}` : ''
			}${discount ? ` AND discountPercentage > ${discount}` : ''}${
				condition
					? ` AND condition:"${condition
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')}"`
					: ''
			}${
				op
					? op === 'designer'
						? ` AND designerExists:true`
						: op === 'branded'
						? ` AND brandExists:1`
						: op === 'Free Shipping'
						? ` AND freeShipping:true`
						: op === 'top-picks'
						? ` AND topPicks:true`
						: op === 'iconic-designs'
						? ` AND iconicDesign:true`
						: ''
					: ''
			}`,
			optionalFilters: region ? [`region:${region}`] : [],
			facets:
				'brand,velouAttributes.styles,velouAttributes.features,newBrand,region,velouAttributes.materials,velouAttributes.rooms,newSchema.categories,newSchema.subCategories,newSchema.types,velouAttributes.manufacturer,velouAttributes.brands,author',
		};

		let brandSearchFacetObj = {
			hitsPerPage: resultsPerPage,
			clickAnalytics: true,
			page: page - 1,
			filters: `status:active${
				tags
					? ` AND tags:"${tags.replace(/-/g, ' ').replace(/\band\b/g, '&')}"`
					: ''
			}${
				color
					? ` AND (velouAttributes.product_color:"${color
							.toLowerCase()
							.replace(/-/g, ' ')
							.replace(
								/\band\b/g,
								'&'
							)}" OR velouAttributes.product_color:"${color
							.toLowerCase()
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')
							.replace(' ', '_')}")`
					: ''
			}${
				category
					? ` AND newSchema.categories:"${category
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')}"`
					: ''
			}${
				subCategory
					? ` AND newSchema.subCategories:"${subCategory
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')}"`
					: ''
			}${
				type
					? ` AND newSchema.types:"${type
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')}"`
					: ''
			}${location ? ` AND region:"${location}"` : ''}${
				style
					? ` AND (velouAttributes.styles:"${style
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')
							.replace(' ', '_')}" OR velouAttributes.styles:"${style
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')}")`
					: ''
			}${
				design
					? ` AND (velouAttributes.design:"${design
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')
							.replace(' ', '_')}" OR velouAttributes.design:"${design
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')}")`
					: ''
			}${
				designer
					? ` AND (designer:"${designer
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')
							.replace(' ', '_')}" OR velouAttributes.brands:"${designer
							.replace(/-/g, ' ')
							.replace(
								/\band\b/g,
								'&'
							)}" OR velouAttributes.designer:"${designer
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')
							.replace(' ', '_')}")`
					: ''
			}${
				material
					? ` AND (velouAttributes.materials:"${material
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')
							.replace(' ', '_')}" OR velouAttributes.materials:"${material
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')}")`
					: ''
			}${
				product_features
					? ` AND (velouAttributes.features:"${product_features
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')
							.replace(
								' ',
								'_'
							)}" OR velouAttributes.features:"${product_features
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')}")`
					: ''
			}${
				room
					? ` AND (velouAttributes.rooms:"${room
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')
							.replace(' ', '_')}" OR velouAttributes.rooms:"${room
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')}")`
					: ''
			}${minPrice ? ` AND price > ${minPrice}` : ''}${
				maxPrice ? ` AND price < ${maxPrice}` : ''
			}${minHeight ? ` AND measurements.H > ${minHeight}` : ''}${
				maxHeight ? ` AND measurements.H < ${maxHeight}` : ''
			}${minLength ? ` AND measurements.L > ${minLength}` : ''}${
				maxLength ? ` AND measurements.L < ${maxLength}` : ''
			}${minDepth ? ` AND measurements.D > ${minDepth}` : ''}${
				maxDepth ? ` AND measurements.D < ${maxDepth}` : ''
			}${discount ? ` AND discountPercentage > ${discount}` : ''}${
				condition
					? ` AND condition:"${condition
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')}"`
					: ''
			}${
				op
					? op === 'designer'
						? ` AND designerExists:true`
						: op === 'branded'
						? ` AND brandExists:1`
						: op === 'Free Shipping'
						? ` AND freeShipping:true`
						: op === 'top-picks'
						? ` AND topPicks:true`
						: op === 'iconic-designs'
						? ` AND iconicDesign:true`
						: ''
					: ''
			}`,
			facets: 'newBrand',
		};
		let styleSearchFacetObj = {
			hitsPerPage: resultsPerPage,
			clickAnalytics: true,
			page: page - 1,
			filters: `status:active${
				tags
					? ` AND tags:"${tags.replace(/-/g, ' ').replace(/\band\b/g, '&')}"`
					: ''
			}${
				color
					? ` AND (velouAttributes.product_color:"${color
							.toLowerCase()
							.replace(/-/g, ' ')
							.replace(
								/\band\b/g,
								'&'
							)}" OR velouAttributes.product_color:"${color
							.toLowerCase()
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')
							.replace(' ', '_')}")`
					: ''
			}${
				category
					? ` AND newSchema.categories:"${category
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')}"`
					: ''
			}${
				subCategory
					? ` AND newSchema.subCategories:"${subCategory
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')}"`
					: ''
			}${
				type
					? ` AND newSchema.types:"${type
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')}"`
					: ''
			}${
				brand
					? ` AND (brand:"${brand
							.replace(/-/g, ' ')
							.replace(
								/\band\b/g,
								'&'
							)}" OR velouAttributes.manufacturer:"${brand
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')}" OR velouAttributes.brands:"${brand
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')
							.replace(' ', '_')}" OR velouAttributes.manufacturer:"${brand
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')
							.replace(' ', '_')}" OR velouAttributes.brands:"${brand
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')
							.replace(' ', '_')}")`
					: ''
			}${location ? ` AND region:"${location}"` : ''}${
				design
					? ` AND (velouAttributes.design:"${design
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')
							.replace(' ', '_')}" OR velouAttributes.design:"${design
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')}")`
					: ''
			}${
				designer
					? ` AND (designer:"${designer
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')
							.replace(' ', '_')}" OR velouAttributes.brands:"${designer
							.replace(/-/g, ' ')
							.replace(
								/\band\b/g,
								'&'
							)}" OR velouAttributes.designer:"${designer
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')
							.replace(' ', '_')}")`
					: ''
			}${
				material
					? ` AND (velouAttributes.materials:"${material
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')
							.replace(' ', '_')}" OR velouAttributes.materials:"${material
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')}")`
					: ''
			}${
				product_features
					? ` AND (velouAttributes.features:"${product_features
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')
							.replace(
								' ',
								'_'
							)}" OR velouAttributes.features:"${product_features
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')}")`
					: ''
			}${
				room
					? ` AND (velouAttributes.rooms:"${room
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')
							.replace(' ', '_')}" OR velouAttributes.rooms:"${room
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')}")`
					: ''
			}${minPrice ? ` AND price > ${minPrice}` : ''}${
				maxPrice ? ` AND price < ${maxPrice}` : ''
			}${minHeight ? ` AND measurements.H > ${minHeight}` : ''}${
				maxHeight ? ` AND measurements.H < ${maxHeight}` : ''
			}${minLength ? ` AND measurements.L > ${minLength}` : ''}${
				maxLength ? ` AND measurements.L < ${maxLength}` : ''
			}${minDepth ? ` AND measurements.D > ${minDepth}` : ''}${
				maxDepth ? ` AND measurements.D < ${maxDepth}` : ''
			}${discount ? ` AND discountPercentage > ${discount}` : ''}${
				condition
					? ` AND condition:"${condition
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')}"`
					: ''
			}${
				op
					? op === 'designer'
						? ` AND designerExists:true`
						: op === 'branded'
						? ` AND brandExists:1`
						: op === 'Free Shipping'
						? ` AND freeShipping:true`
						: op === 'top-picks'
						? ` AND topPicks:true`
						: op === 'iconic-designs'
						? ` AND iconicDesign:true`
						: ''
					: ''
			}`,
			facets: 'velouAttributes.styles',
		};
		let materialSearchFacetObj = {
			hitsPerPage: resultsPerPage,
			clickAnalytics: true,
			page: page - 1,
			filters: `status:active${
				tags
					? ` AND tags:"${tags.replace(/-/g, ' ').replace(/\band\b/g, '&')}"`
					: ''
			}${
				color
					? ` AND (velouAttributes.product_color:"${color
							.toLowerCase()
							.replace(/-/g, ' ')
							.replace(
								/\band\b/g,
								'&'
							)}" OR velouAttributes.product_color:"${color
							.toLowerCase()
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')
							.replace(' ', '_')}")`
					: ''
			}${
				category
					? ` AND newSchema.categories:"${category
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')}"`
					: ''
			}${
				subCategory
					? ` AND newSchema.subCategories:"${subCategory
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')}"`
					: ''
			}${
				type
					? ` AND newSchema.types:"${type
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')}"`
					: ''
			}${
				brand
					? ` AND (brand:"${brand
							.replace(/-/g, ' ')
							.replace(
								/\band\b/g,
								'&'
							)}" OR velouAttributes.manufacturer:"${brand
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')}" OR velouAttributes.brands:"${brand
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')
							.replace(' ', '_')}" OR velouAttributes.manufacturer:"${brand
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')
							.replace(' ', '_')}" OR velouAttributes.brands:"${brand
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')
							.replace(' ', '_')}")`
					: ''
			}${location ? ` AND region:"${location}"` : ''}${
				style
					? ` AND (velouAttributes.styles:"${style
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')
							.replace(' ', '_')}" OR velouAttributes.styles:"${style
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')}")`
					: ''
			}${
				design
					? ` AND (velouAttributes.design:"${design
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')
							.replace(' ', '_')}" OR velouAttributes.design:"${design
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')}")`
					: ''
			}${
				designer
					? ` AND (designer:"${designer
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')
							.replace(' ', '_')}" OR velouAttributes.brands:"${designer
							.replace(/-/g, ' ')
							.replace(
								/\band\b/g,
								'&'
							)}" OR velouAttributes.designer:"${designer
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')
							.replace(' ', '_')}")`
					: ''
			}${
				product_features
					? ` AND (velouAttributes.features:"${product_features
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')
							.replace(
								' ',
								'_'
							)}" OR velouAttributes.features:"${product_features
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')}")`
					: ''
			}${
				room
					? ` AND (velouAttributes.rooms:"${room
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')
							.replace(' ', '_')}" OR velouAttributes.rooms:"${room
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')}")`
					: ''
			}${minPrice ? ` AND price > ${minPrice}` : ''}${
				maxPrice ? ` AND price < ${maxPrice}` : ''
			}${minHeight ? ` AND measurements.H > ${minHeight}` : ''}${
				maxHeight ? ` AND measurements.H < ${maxHeight}` : ''
			}${minLength ? ` AND measurements.L > ${minLength}` : ''}${
				maxLength ? ` AND measurements.L < ${maxLength}` : ''
			}${minDepth ? ` AND measurements.D > ${minDepth}` : ''}${
				maxDepth ? ` AND measurements.D < ${maxDepth}` : ''
			}${discount ? ` AND discountPercentage > ${discount}` : ''}${
				condition
					? ` AND condition:"${condition
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')}"`
					: ''
			}${
				op
					? op === 'designer'
						? ` AND designerExists:true`
						: op === 'branded'
						? ` AND brandExists:1`
						: op === 'Free Shipping'
						? ` AND freeShipping:true`
						: op === 'top-picks'
						? ` AND topPicks:true`
						: op === 'iconic-designs'
						? ` AND iconicDesign:true`
						: ''
					: ''
			}`,
			facets: 'velouAttributes.materials',
		};
		let locationSearchFacetObj = {
			hitsPerPage: 0,
			clickAnalytics: true,
			page: 0,
			filters: `status:active${
				tags
					? ` AND tags:"${tags.replace(/-/g, ' ').replace(/\band\b/g, '&')}"`
					: ''
			}${
				color
					? ` AND (velouAttributes.product_color:"${color
							.toLowerCase()
							.replace(/-/g, ' ')
							.replace(
								/\band\b/g,
								'&'
							)}" OR velouAttributes.product_color:"${color
							.toLowerCase()
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')
							.replace(' ', '_')}")`
					: ''
			}${
				category
					? ` AND newSchema.categories:"${category
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')}"`
					: ''
			}${
				subCategory
					? ` AND newSchema.subCategories:"${subCategory
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')}"`
					: ''
			}${
				type
					? ` AND newSchema.types:"${type
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')}"`
					: ''
			}${
				brand
					? ` AND (brand:"${brand
							.replace(/-/g, ' ')
							.replace(
								/\band\b/g,
								'&'
							)}" OR velouAttributes.manufacturer:"${brand
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')}" OR velouAttributes.brands:"${brand
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')
							.replace(' ', '_')}" OR velouAttributes.manufacturer:"${brand
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')
							.replace(' ', '_')}" OR velouAttributes.brands:"${brand
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')
							.replace(' ', '_')}")`
					: ''
			}${
				style
					? ` AND (velouAttributes.styles:"${style
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')
							.replace(' ', '_')}" OR velouAttributes.styles:"${style
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')}")`
					: ''
			}${
				design
					? ` AND (velouAttributes.design:"${design
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')
							.replace(' ', '_')}" OR velouAttributes.design:"${design
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')}")`
					: ''
			}${
				designer
					? ` AND (designer:"${designer
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')
							.replace(' ', '_')}" OR velouAttributes.brands:"${designer
							.replace(/-/g, ' ')
							.replace(
								/\band\b/g,
								'&'
							)}" OR velouAttributes.designer:"${designer
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')
							.replace(' ', '_')}")`
					: ''
			}${
				material
					? ` AND (velouAttributes.materials:"${material
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')
							.replace(' ', '_')}" OR velouAttributes.materials:"${material
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')}")`
					: ''
			}${
				product_features
					? ` AND (velouAttributes.features:"${product_features
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')
							.replace(
								' ',
								'_'
							)}" OR velouAttributes.features:"${product_features
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')}")`
					: ''
			}${
				room
					? ` AND (velouAttributes.rooms:"${room
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')
							.replace(' ', '_')}" OR velouAttributes.rooms:"${room
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')}")`
					: ''
			}${minPrice ? ` AND price > ${minPrice}` : ''}${
				maxPrice ? ` AND price < ${maxPrice}` : ''
			}${minHeight ? ` AND measurements.H > ${minHeight}` : ''}${
				maxHeight ? ` AND measurements.H < ${maxHeight}` : ''
			}${minLength ? ` AND measurements.L > ${minLength}` : ''}${
				maxLength ? ` AND measurements.L < ${maxLength}` : ''
			}${minDepth ? ` AND measurements.D > ${minDepth}` : ''}${
				maxDepth ? ` AND measurements.D < ${maxDepth}` : ''
			}${discount ? ` AND discountPercentage > ${discount}` : ''}${
				condition
					? ` AND condition:"${condition
							.replace(/-/g, ' ')
							.replace(/\band\b/g, '&')}"`
					: ''
			}${
				op
					? op === 'designer'
						? ` AND designerExists:true`
						: op === 'branded'
						? ` AND brandExists:1`
						: op === 'Free Shipping'
						? ` AND freeShipping:true`
						: op === 'top-picks'
						? ` AND topPicks:true`
						: op === 'iconic-designs'
						? ` AND iconicDesign:true`
						: ''
					: ''
			}`,
			facets: 'region',
		};

		if (lat && lng && sort == 7) {
			searchObj.aroundLatLng = `${lat},${lng}`;
			searchObj.aroundRadius = 'all';
			searchObj.getRankingInfo = true;
		}

		const getSearchResults = async () => {
			let res = await index
				.search(keyword ? keyword : '', searchObj, {
					clickAnalytics: true,
					enablePersonalization: true,
					userToken: userToken ? userToken : null,
				})
				.then(async (res) => {
					if (res?.queryID) localStorage.setItem('queryID', res.queryID);
					let nowSearch = global?.window && window.location.search;

					if (beforeSearch !== nowSearch) {
						return null;
					}

					return res;
				})
				.catch((err) => {
					console.log(err);
					return null;
				});
			return res;
		};
		const getBrandFacets = async () => {
			if (brand) {
				let res = await index
					.search(keyword ? keyword : '', brandSearchFacetObj)
					.then(async (res) => {
						let nowSearch = global?.window && window.location.search;

						if (beforeSearch !== nowSearch) {
							return null;
						}

						return res;
					})
					.catch((err) => {
						console.log(err);
						return null;
					});
				return res;
			} else {
				return null;
			}
		};
		const getStyleFacets = async () => {
			if (style) {
				let res = await index
					.search(keyword ? keyword : '', styleSearchFacetObj)
					.then(async (res) => {
						let nowSearch = global?.window && window.location.search;

						if (beforeSearch !== nowSearch) {
							return null;
						}

						return res;
					})
					.catch((err) => {
						console.log(err);
						return null;
					});
				return res;
			} else {
				return null;
			}
		};
		const getMaterialFacets = async () => {
			if (material) {
				let res = await index
					.search(keyword ? keyword : '', materialSearchFacetObj)
					.then(async (res) => {
						let nowSearch = global?.window && window.location.search;

						if (beforeSearch !== nowSearch) {
							return null;
						}

						return res;
					})
					.catch((err) => {
						console.log(err);
						return null;
					});
				return res;
			} else {
				return null;
			}
		};
		const getLocationFacets = async () => {
			if (location) {
				let res = await index
					.search(keyword ? keyword : '', locationSearchFacetObj)
					.then(async (res) => {
						let nowSearch = global?.window && window.location.search;

						if (beforeSearch !== nowSearch) {
							return null;
						}

						return res;
					})
					.catch((err) => {
						console.log(err);
						return null;
					});
				return res;
			} else {
				return null;
			}
		};

		let [
			searchResults,
			brandFacets,
			styleFacets,
			materialFacets,
			locationFacets,
		] = await Promise.all([
			getSearchResults(),
			getBrandFacets(),
			getStyleFacets(),
			getMaterialFacets(),
			getLocationFacets(),
		]);

		if (searchResults) {
			setListings((prevState) => ({
				...prevState,
				loading: false,
				listings: searchResults.hits,
				paginationData: {
					totalListings: searchResults.nbHits,
					currentPage: searchResults.page + 1,
					totalPages: searchResults.nbPages,
					listingsPerPage: searchResults.hitsPerPage,
				},
				uniqueBrands: brandFacets
					? brandFacets.facets.newBrand
						? Object.entries(brandFacets.facets.newBrand).map(
								([name, count]) => ({
									name,
									count,
								})
						  )
						: []
					: searchResults.facets.newBrand
					? Object.entries(searchResults.facets.newBrand).map(
							([name, count]) => ({
								name,
								count,
							})
					  )
					: [],
				uniqueRegions: locationFacets
					? locationFacets.facets.region
						? Object.entries(locationFacets.facets.region).map(
								([region, count]) => ({
									region,
									count,
								})
						  )
						: []
					: searchResults.facets.region
					? Object.entries(searchResults.facets.region).map(
							([region, count]) => ({
								region,
								count,
							})
					  )
					: [],
				uniqueMaterials: materialFacets
					? materialFacets.facets['velouAttributes.materials']
					: searchResults.facets['velouAttributes.materials'],
				uniqueStyles: styleFacets
					? styleFacets.facets['velouAttributes.styles']
					: searchResults.facets['velouAttributes.styles'],
				uniqueRooms: searchResults.facets['velouAttributes.rooms'],
				uniqueCategories: searchResults.facets['newSchema.categories']
					? Object.entries(searchResults.facets['newSchema.categories']).map(
							([name, count]) => ({ name, count })
					  )
					: [],
				uniqueSubCategories: searchResults.facets['newSchema.subCategories']
					? Object.entries(searchResults.facets['newSchema.subCategories']).map(
							([name, count]) => ({ name, count })
					  )
					: [],
				uniqueTypes: searchResults.facets['newSchema.types']
					? Object.entries(searchResults.facets['newSchema.types']).map(
							([name, count]) => ({
								name,
								count,
							})
					  )
					: [],
			}));
		} else {
			setListings((prevState) => ({
				...prevState,
				loading: false,
			}));
		}
	};

	const LOAD_ALL_NUMBERS = async () => {
		setListings((prevState) => ({
			...prevState,
			all: {
				...prevState.all,
				loading: true,
			},
		}));

		const index = client.initIndex('kashew_listings');
		await index
			.search('', {
				hitsPerPage: 52,
				clickAnalytics: true,
				page: 0,
				facets:
					'newBrand,velouAttributes.styles,region,velouAttributes.materials,velouAttributes.rooms,newSchema.categories,newSchema.subCategories,newSchema.types',
			})
			.then(async (res) => {
				setListings((prevState) => ({
					...prevState,
					all: {
						loading: false,
						paginationData: {
							totalListings: res.nbHits,
							currentPage: res.page + 1,
							totalPages: res.nbPages,
							listingsPerPage: res.hitsPerPage,
						},
						uniqueBrands: res.facets.newBrand
							? Object.entries(res.facets.newBrand).map(([name, count]) => ({
									name,
									count,
							  }))
							: [],
						uniqueRegions: res.facets.region
							? Object.entries(res.facets.region).map(([region, count]) => ({
									region,
									count,
							  }))
							: [],
						uniqueMaterials: res.facets['velouAttributes.materials'],
						uniqueStyles: res.facets['velouAttributes.styles'],
						uniqueRooms: res.facets['velouAttributes.rooms'],
						uniqueCategories: res.facets['newSchema.categories']
							? Object.entries(res.facets['newSchema.categories']).map(
									([name, count]) => ({ name, count })
							  )
							: [],
						uniqueSubCategories: res.facets['newSchema.subCategories']
							? Object.entries(res.facets['newSchema.subCategories']).map(
									([name, count]) => ({ name, count })
							  )
							: [],
						uniqueTypes: res.facets['newSchema.types']
							? Object.entries(res.facets['newSchema.types']).map(
									([name, count]) => ({ name, count })
							  )
							: [],
					},
				}));
			})
			.catch(async (error) => {
				console.log(error);
				setListings((prevState) => ({
					...prevState,
					all: {
						...prevState.all,
						loading: false,
					},
				}));
			});
	};

	useEffect(() => {
		const isBot = /bot|crawl|slurp|spider|Prerender|facebookexternalhit/i.test(
			navigator?.userAgent
		);
		if (!isBot) {
			LOAD_ALL_NUMBERS();
		}
	}, []);

	return (
		<ListingsContext.Provider
			value={{
				listings,
				LOAD_LISTINGS,
				LOAD_ALL_NUMBERS,
			}}
		>
			{children}
		</ListingsContext.Provider>
	);
};

export const useListingsContext = () => {
	return useContext(ListingsContext);
};
