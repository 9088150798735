import moment from 'moment';
import { RatingView } from 'react-simple-star-rating';
import classes from './GoogleReviewCard.module.css';

const SellerReviewsCard = ({ review }) => {
	const abbreviateLastName = (fullName) => {
		const nameParts = fullName.split(' ');

		if (nameParts.length >= 2) {
			return `${nameParts[0]} ${nameParts[nameParts.length - 1].charAt(0)}.`;
		}

		return fullName;
	};

	return (
		<div className={classes.cardMain}>
			{review && (
				<div className={classes.border}>
					<div className={classes.reviewShowContainer}>
						<div className={classes.reviewShowProfile}>
							<div className={classes.profileName}>
								{/* <img
                  src={`${review.buyer.user.picture}?tr=w-100`}
                  referrerPolicy="no-referrer"
                  className={classes.reviewShowImg}
                  alt={"Review on buying used furniture kashew"}
                /> */}
								<div className={classes.showProfileData}>
									<h6 className={classes.name}>
										{abbreviateLastName(review.buyer.user.firstName)}
									</h6>

									<section className={classes.rowGap}>
										<div className={classes.time}>
											{moment(review.order.date).format('MMM D, YYYY')}
										</div>
										<div
											className={
												review?.order?.breakdown?.delivery > 0
													? classes.badge
													: classes.otherBadge
											}
										>
											{review?.order?.breakdown?.delivery > 0
												? 'Delivery'
												: 'Pick-up'}
										</div>
									</section>
								</div>
							</div>
							<RatingView
								size={20}
								ratingValue={review.seller.rating}
								fillColor="#62646a"
							/>
						</div>

						<div className={classes.reviewShowDescription1}>
							{review.seller.feedback}
						</div>
						{review.listings?.length &&
							review.listings[0].listing?.images[0] &&
							review.listings.map((listing, idx) => (
								<div key={idx} className={classes.listingContainer}>
									<img
										src={listing?.listing?.images[1]}
										className={classes.listingImg}
										alt={listing?.listing?.title}
									/>
									<div className={classes.listingInfo}>
										<h6 className={classes.listingTitle}>
											{listing?.listing?.title}
										</h6>
									</div>
								</div>
							))}
					</div>
				</div>
			)}
		</div>
	);
};

export default SellerReviewsCard;
