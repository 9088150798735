"use client";
import { AnimatePresence, motion } from "framer-motion";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { X } from "react-feather";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { io } from "socket.io-client";
import classes from "./AllChatsOverlay.module.css";
import ChatSection from "./Components/ChatSection";
import ChatsPreview from "./Components/ChatsPreview";
import ChatsPreviewPlaceholder from "./Placeholders/ChatsPreviewPlaceholder";
import { useAuthContext } from "@/context";
import { useChatsContext } from "@/context/chats";

const AllChatsOverlay = ({
  open,
  setOpen,
  chatRef,
  chatSelection,
  setChatSelection,
}) => {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const [socket, setSocket] = useState(null);
  const { auth } = useAuthContext();
  const { chats, GET_ALL_CHATS } = useChatsContext();

  // Initialize socket connection when chatSelection changes
  useEffect(() => {
    if (chatSelection) {
      const newSocket = io(process.env.NEXT_PUBLIC_SERVER_API, {
        withCredentials: true,
        transports: ["websocket", "polling"],
      });
      setSocket(newSocket);
    }
  }, [chatSelection]);

  // Function to fetch chat data
  const getChat = async (chatId) => {
    if (!chatId) return;
    try {
      const res = await axios.get(
        `${process.env.NEXT_PUBLIC_SERVER_API}/api/chat/${chatId}`
      );
      setChatSelection(res.data);
    } catch (error) {
      console.error("Error fetching chat data:", error);
    }
  };

  // Effect to handle query params on initial load and reload
  useEffect(() => {
    const chatId = searchParams.get("chat");
    if (chatId && !chatSelection && auth.user) {
      getChat(chatId);
    }
  }, [searchParams, pathname, auth.user]);

  // Close chat overlay and remove query param
  const closeHandler = () => {
    setOpen(false);
    const params = new URLSearchParams(searchParams);
    params.delete("chat");
    router.push(`${pathname}?${params.toString()}`, undefined, {
      shallow: true,
    });
    setChatSelection(null);
  };

  // Handle selection of a chat from chat preview
  const chatHandler = (input) => {
    const chat = input && input !== "null" ? input : "";
    const params = new URLSearchParams(searchParams);
    params.set("chat", chat);
    router.push(`${pathname}?${params.toString()}`, undefined, {
      shallow: true,
    });
    getChat(chat);
  };

  // Fetch all chats when user is authenticated
  useEffect(() => {
    if (
      !chats.loading &&
      auth.isAuthenticated &&
      chats.chats.length === 0 &&
      !chats.loaded
    ) {
      GET_ALL_CHATS();
    }
  }, [chats, auth]);

  return (
    <>
      <AnimatePresence>
        {open && (
          <motion.div
            ref={chatRef}
            initial={{ opacity: 0, x: "500px" }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: "500px" }}
            transition={{
              duration: 0.6,
              type: "spring",
              stiffness: 400,
              damping: 40,
            }}
            className={classes.offCanvas}
          >
            {" "}
            {chatSelection && chatSelection?.order && socket ? (
              <ChatSection
                chatSelection={chatSelection}
                setChatSelection={setChatSelection}
                socket={socket}
                setOpen={setOpen}
              />
            ) : auth.loading || (chats.loading && chats.chats.length === 0) ? (
              <ChatsPreviewPlaceholder />
            ) : !auth.isAuthenticated ? (
              <div className={classes.header}>
                You need to be logged in first{" "}
                <X
                  className={classes.closeIcon}
                  onClick={() => closeHandler()}
                />
              </div>
            ) : (
              <>
                <div className={classes.header}>
                  Messages{" "}
                  <X
                    className={classes.closeIcon}
                    onClick={() => closeHandler()}
                  />
                </div>
                <ChatsPreview
                  chatHandler={chatHandler}
                  sender={auth.user}
                  setChatSelection={setChatSelection}
                  chatSelection={chatSelection}
                />
              </>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default AllChatsOverlay;
