import React from "react";
import { motion } from "framer-motion";

const BurgerIcon = ({
  isOpen = false,
  width = 15,
  height = 15,
  strokeWidth = 2,
  color = "#fd589e",
  colorClosed = "#070035",
  transition = { duration: 0.3 },
  lineProps = null,
  ...props
}) => {
  const variant = isOpen ? "opened" : "closed";

  // Framer Motion Variants
  const top = {
    closed: {
      rotate: 0,
      y: 0,
    },
    opened: {
      rotate: 45,
      y: "3px", // Move down to the middle for rotation
    },
  };

  const center = {
    closed: {
      opacity: 1,
    },
    opened: {
      opacity: 0, // Fade out in the middle
    },
  };

  const bottom = {
    closed: {
      rotate: 0,
      y: 0,
    },
    opened: {
      rotate: -45,
      y: "-3px", // Move up to the middle for rotation
    },
  };

  lineProps = {
    stroke: isOpen ? color : colorClosed,
    strokeWidth: strokeWidth,
    vectorEffect: "non-scaling-stroke",
    initial: "closed",
    animate: variant,
    transition,
    transformOrigin: "center", // Key fix: Rotate lines around the center
    ...lineProps,
  };

  const unitHeight = 8;
  const unitWidth = unitHeight * (width / height);

  return (
    <motion.svg
      viewBox={`0 0 ${unitWidth} ${unitHeight}`}
      overflow="visible"
      preserveAspectRatio="none"
      width={width}
      height={height}
      {...props}
    >
      {/* Top Line */}
      <motion.line
        x1="0"
        x2={unitWidth}
        y1="2"
        y2="2"
        variants={top}
        {...lineProps}
      />
      {/* Center Line */}
      <motion.line
        x1="0"
        x2={unitWidth}
        y1="5"
        y2="5"
        variants={center}
        {...lineProps}
      />
      {/* Bottom Line */}
      <motion.line
        x1="0"
        x2={unitWidth}
        y1="8"
        y2="8"
        variants={bottom}
        {...lineProps}
      />
    </motion.svg>
  );
};

export { BurgerIcon };
