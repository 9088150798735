"use client";
import classes from "./navigational-bar.module.css";
import MainNavigation from "./components/main-navigation";
import SecondaryNavbarMobile from "./components/secondary-navbar-mobile";
import { usePathname } from "next/navigation";

// //       {pathname !== "/login" &&
// pathname !== "/register" &&
// pathname !== "/checkout" &&
// pathname !== "/consign-form" &&
// !pathname.includes("order-summary") &&
// !pathname.includes("info") &&
// !pathname.includes("help") ? (

export default function NavigationalBar() {
  const hiddenRoutes = [
    "/login",
    "/register",
    "/checkout",
    "/consign-form",
    "order-summary",
    "info",
    "help",
  ];

  const pathname = usePathname();
  return (
    <>
      {hiddenRoutes.includes(pathname) ? null : (
        <nav className={classes.wrapper}>
          <MainNavigation />
          <SecondaryNavbarMobile />
        </nav>
      )}
    </>
  );
}
