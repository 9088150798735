'use client';
import { usePathname } from 'next/navigation';
import FooterLinks from './components/footer-links';
import FooterReviews from './components/footer-reviews';
import FooterValueProps from './components/footer-value-props';
import classes from './footer-new.module.css';

export default function Footer() {
	const pathname = usePathname();

	// Ensure pathname is available before proceeding
	if (!pathname) return null;

	const hiddenRoutes = new Set([
		'/login',
		'/register',
		'/consign-with-us',
		'/seller-dashboard',
		'/consign-form',
		'/admin',
		'/cart',
	]);

	const hiddenPatterns = [
		'/edit',
		'/chat',
		'/checkout',
		'/orders',
		'order-summary',
		'seller-dashboard',
	];

	const isHidden =
		hiddenRoutes.has(pathname) ||
		hiddenPatterns.some((route) => pathname.includes(route));

	if (isHidden) return null;

	return (
		<footer className={classes.footer}>
			<FooterValueProps />
			<FooterReviews />
			<FooterLinks />
		</footer>
	);
}
